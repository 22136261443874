import React from 'react'
import { graphql } from 'gatsby'

import SEO from "../components/seo"
import Header from "../components/Header"
import Footer from "../components/Footer"

import PagePartRenderer from "../components/PageParts/PagePartRenderer"

export const query = graphql`
  query InsidePageTemplateQuery($id: String!)
  {
    pageData: sanityPage(id: {eq: $id})
    {
      _id
      metadata {
        title: page_title
        keywords: page_keywords
        description: page_description
        canonical: page_canonical_href
        nofollow: page_robot_nofollow
        noindex: page_robot_noindex
      }
      socialMetadata {
        type: page_og_type
        title: page_og_title
        description: page_og_description
        image: page_og_image {
          _key
          asset {
            _key
            url
          }
        }
        twitter_title: page_og_twitter_title
        twitter_description: page_og_twitter_description
        twitter_creator: page_og_twitter_creator
        twitter_image: page_og_twitter_image {
          _key
          asset {
            _key
            url
          }
        }
      }
      breadcrumbEnabled
      breadcrumbTitle
      breadcrumb {
        breadcrumbTitle
        _rawBreadcrumb(resolveReferences: {maxDepth: 2})
      }
      slug {
        current
      }
      ...pagePartsQuery
    }
  }
`

const PageTemplate = props => {
  const { data } = props
  const page = data && data.pageData

  return (
    <>
      { page && 
        <>
          <Header whiteLogo={true} breadcrumbTitle={ page.breadcrumbTitle } breadcrumb={ page.breadcrumb } breadcrumbEnabled={ page.breadcrumbEnabled } />
          <SEO metadata={page.metadata} socialmetadata={page.socialMetadata} />
          { page.pageParts && page.pageParts.map((part, i) => (
              <PagePartRenderer part={ part } key={i} />
            ))
          }
          <Footer />
        </>
      }

      {! page &&
        <>
          <SEO title="Home" />
          <Header />
          <div className="p-10 text-center">
            <h1>Yoh... something is fubar</h1>
          </div>
        </>
      }
    </>
  )
}

export default PageTemplate
